<template>
  <form-view
    :url="`/site/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :before-submit="beforeSubmit"
  >
    <div class="section-title-row">
      <div class="title">基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="工地名称" prop="siteName">
          <el-input v-model="dataForm.siteName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="工地地址" prop="siteAddress">
          <el-input v-model="dataForm.siteAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="负责人" prop="headUserName">
          <el-select filterable v-model="dataForm.headUserId">
            <el-option v-for="s in principalList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所属基地" prop="baseId">
          <el-select filterable v-model="dataForm.baseId">
            <el-option v-for="b in baseList" :key="b.id" :label="b.name" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <!-- <el-col :span="6">
        <el-form-item label-width="82px"  label="工地电话" prop="scenePhone">
          <el-input type="number" v-model="dataForm.scenePhone"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-form-item label="施工单位" prop="constructionUnit">
          <el-input v-model="dataForm.constructionUnit"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="拌台" prop="mixingTable">
          <el-input v-model="dataForm.mixingTable"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6" class="flex-spread">
        <el-form-item label="局内局外" prop="insider">
          <el-select v-model="dataForm.insider">
            <el-option label="局内" value="局内"></el-option>
            <el-option label="局外" value="局外"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="甲供选择" prop="nailSupply">
          <el-checkbox v-model="dataForm.nailSupply">甲供</el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <!-- <el-col :span="6">
        <el-form-item label-width="82px" label="项目部电话" prop="departmentPhone">
          <el-input type="number" v-model="dataForm.departmentPhone"></el-input>
        </el-form-item>
      </el-col> -->
      <!--      <el-col :span="6">-->
      <!--        <el-form-item label="勘察人" prop="principalName">-->
      <!--          <el-select filterable v-model="dataForm.userId">-->
      <!--            <el-option v-for="s in principalList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->
      <el-col :span="6">
        <el-form-item label="泵管属性" prop="bgProperty">
          <el-input v-model="dataForm.bgProperty"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="18">
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="dataForm.remarks" placeholder="备注基本施工注意或者补充内容"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">工地联系人信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="6" v-for="h in dataForm.siteHeadList" :key="h.id">
        <el-card>
          <el-form-item label-width="82px" label="负责人">
            <el-input v-model="h.userName"></el-input>
          </el-form-item>
          <el-form-item label-width="82px" label="负责人电话" style="margin-bottom:0;">
            <el-input type="number" v-model="h.userPhone"></el-input>
          </el-form-item>
          <i
            class="el-icon-circle-close close-icon"
            v-if="dataForm.siteHeadList.length > 1"
            @click="removeHead(h)"
          ></i>
        </el-card>
      </el-col>
      <el-col :span="6">
        <i class="el-icon-circle-plus-outline add-contact-icon" @click="addHead"></i>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">危险源信息</div>
      <div class="line"></div>
    </div>
    <el-row class="flex-level">
      <el-checkbox-group v-model="dataForm.dangers">
        <el-checkbox v-for="d in dangerList" :key="d.dictId" :label="d.dictId">{{d.dictName}}</el-checkbox>
      </el-checkbox-group>
      <el-input v-model="dataForm.hazardOtherRemarks" style="width: 180px;margin-left: 20px;"></el-input>
    </el-row>
    <image-uploader
      title="危险源现场图"
      @add="addLicenseImage($event, 'dangers')"
      @remove="removeLicenseImage($event, 'dangers')"
      :images="dangersImageList || []"
      show-create-date
    ></image-uploader>
    <image-uploader
      title="工地告知书"
      @add="addLicenseImage($event, 'siteNotice')"
      @remove="removeLicenseImage($event, 'siteNotice')"
      :images="siteNoticeImageList || []"
      show-create-date
    ></image-uploader>
    <image-uploader
      title="施工图"
      @add="addLicenseImage($event, 'workingDrawing')"
      @remove="removeLicenseImage($event, 'workingDrawing')"
      :images="workingDrawingImageList || []"
      show-create-date
    ></image-uploader>
    <image-uploader
      title="现场图"
      @add="addLicenseImage($event, 'scene')"
      @remove="removeLicenseImage($event, 'scene')"
      :images="sceneImageList || []"
      show-create-date
    ></image-uploader>
    <div class="section-title-row" v-if="id">
      <div class="title">勘察记录</div>
      <div class="line"></div>
    </div>
    <el-table
      v-if="id"
      :data="tableData"
      stripe
      size="mini"
      :border="true"
      :max-height="300"
    >
      <el-table-column
        header-align="center"
        align="center"
        v-for="h in headers"
        :key="h.id"
        :label="h.label"
        :prop="h.prop"
        :width="h.width"
        :min-width="h.minWidth"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'

export default {
  name: 'dispatch-site-update',

  components: {
    FormView,
    ImageUploader
  },

  data () {
    return {
      loading: false,
      dataForm: {},
      baseList: [],
      dangerList: [],
      dangersImageList: [],
      siteNoticeImageList: [],
      workingDrawingImageList: [],
      sceneImageList: [],
      id: null,
      tableData: [],
      headers: [
        { id: 1, label: '勘察时间', prop: 'updateTime', width: 150 },
        { id: 2, label: '堪察人', prop: 'nickName', width: 150 },
        { id: 3, label: '危险源', prop: 'dangers', width: 300 },
        { id: 4, label: '备注', prop: 'remarks', minWidth: 120 }
      ],
      principalList: []
    }
  },

  async created () {
    const userData = await this.$http({
      url: this.$http.adornUrl('/user/list'),
      method: 'post',
      data: {
        userType: 1
      }
    })
    this.principalList = userData.datas
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/site/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          if (this.dataForm.dangers) {
            this.dataForm.dangers = this.dataForm.dangers.split(',').map(d => parseInt(d))
          } else {
            this.dataForm.dangers = []
          }
          this.dataForm.nailSupply = this.dataForm.nailSupply === '1'
          if (!this.dataForm.siteHeadList.length) {
            this.addHead()
          }
          this.processImageList('dangersImageList')
          this.processImageList('siteNoticeImageList')
          this.processImageList('workingDrawingImageList')
          this.processImageList('sceneImageList')
          const records = await this.$http({
            url: this.$http.adornUrl('/site/operationRecord'),
            method: 'post',
            data: id
          })
          this.tableData = records.datas
          this.loading = false
        }
      } else {
        this.dataForm = {
          siteName: '',
          siteAddress: '',
          headUserId: '',
          baseId: '',
          scenePhone: '',
          constructionUnit: '',
          mixingTable: '',
          nailSupply: false,
          insider: '',
          departmentPhone: '',
          remarks: '',
          bgProperty: '',
          siteHeadList: [{
            id: 0,
            userName: '',
            userPhone: '',
            isNew: true
          }],
          dangers: [],
          hazardOtherRemarks: '',
          dangersImageList: [],
          siteNoticeImageList: [],
          workingDrawingImageList: [],
          sceneImageList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/user/base/list'),
        method: 'post'
      })
      this.baseList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/site/list/dangers'),
        method: 'post'
      })
      this.dangerList = data.datas
    },

    processImageList (name) {
      if (this.dataForm[name]) {
        this[name] = this.dataForm[name].map(i => ({
          id: i.id,
          url: '/files' + i.url,
          createTime: i.createTime
        }))
        this.dataForm[name] = this.dataForm[name].map(i => i.url)
      }
    },

    beforeSubmit (params) {
      const {
        dangers,
        nailSupply,
        siteHeadList,
        ...others
      } = params
      console.log(this.dataForm.siteHeadList)
      return {
        ...others,
        dangers: dangers.join(','),
        siteHeadList: siteHeadList.filter(s => s.userPhone).map(s => {
          const m = {
            userName: s.userName,
            userPhone: s.userPhone
          }
          !s.isNew && (m.id = s.id)
          return m
        }),
        nailSupply: nailSupply ? 1 : 2
      }
    },

    addHead () {
      this.dataForm.siteHeadList.push({
        id: Math.random(),
        isNew: true,
        userName: '',
        userPhone: ''
      })
    },

    addLicenseImage (url, name) {
      this.dataForm[`${name}ImageList`].push(url)
    },

    removeLicenseImage (url, name) {
      const list = this.dataForm[`${name}ImageList`]
      list.splice(list.indexOf(url), 1)
    },

    removeHead (m) {
      this.dataForm.siteHeadList.splice(
        this.dataForm.siteHeadList.indexOf(m),
        1
      )
    }
  }
}

</script>

<style lang="scss" scoped>
.el-card {
  padding-right: 20px;
  position: relative;
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #ddd;

    &:hover {
      color: #999;
    }
  }
}
.add-contact-icon {
  margin: 50px 0;
  font-size: 36px;
  cursor: pointer;
  color: #ddd;
  &:hover {
    color: #999;
  }
}
.el-table {
  margin-bottom: 20px;
}
</style>
